import React, { useState, useEffect, useContext, useRef } from 'react'
import MAINLAYOUT from '../components/layouts/mainLayout.jsx'
import { GoogleApiWrapper } from 'google-maps-react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Auth } from 'aws-amplify'
import BOTTOM_MENU from '../components/bottom_menu/bottom_menu.jsx'
import Autosuggest from 'react-autosuggest'
import '../assets/css/autosuggest.css'
import { GlobalCategoriesContext } from '../context/GlobalContextProvider'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import GOOGLEMAP from '../components/googlemap/googlemap.jsx'

const Global = require('../context/Global')

const shareit = props => {
  const { pinIcon } = useStaticQuery(graphql`
    query {
      pinIcon: file(relativePath: { eq: "img/current_pin.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const refMap = useRef(null)
  let map = void 0
  const { fetchCategories, storeDataUpload, getAuthenticatedUser } = useContext(
    GlobalCategoriesContext
  )

  const [categories, setCategories] = useState([])
  const [product_qty, setProductQty] = useState('')
  const [loading, setLoading] = useState(false)
  const [userLocation, setUserLocation] = useState(void 0)
  const [selectedFile, setSelectedFile] = useState(void 0)
  const [userData, setUserData] = useState(void 0)
  const [searchQuery, setSearchQuery] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [searchItem, setSearchItem] = useState('')
  const [nearestRetailers, setNearestRetailers] = useState([])
  const [selectedRetailer, setSelectedRetailer] = useState(void 0)
  const [viewMap, setViewMap] = useState(false)
  const [searchRadius, setSearchRadius] = useState(Global.initialSearchRadius)
  const [retailersLimit, setRetailersLimit] = useState(Global.retailersLimit)
  const [offset, setOffSet] = useState(0)
  const [notFound, setNotFound] = useState(false)

  const getUserLocation = async () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          let pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          setUserLocation(pos)

          _getNearestRetailers(pos)
        },
        error => {
          console.error('Error Code = ' + error.code + ' - ' + error.message)
        }
      )
    }
  }

  const _reverseGeolocation = async currentLocation => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lng}&key=AIzaSyAQhra2X6N6O4LcJpll2Y1qJzFaDuwvMbY`
    )
      .then(result => {
        return result.json()
      })
      .then(data => {
        setFormattedAddress(data.results[2].formatted_address)
      })
  }
  let tmp = []
  const _getNearestRetailers = pos => {
    if (pos) {
      const maps = google.maps
      const node = refMap.current
      map = new maps.Map(node, {
        center: { lat: pos.lat, lng: pos.lng },
      })
      const service = new maps.places.PlacesService(map)
      const request = {
        location: pos,
        //radius: searchRadius / Global.currentZoom,
        radius: searchRadius,
        type: ['store'],
      }

      console.log(request.radius)

      service.nearbySearch(request, (results, status, pagination) => {
        if (status === maps.places.PlacesServiceStatus.OK) {
          if (results.length == 0 && searchRadius < 50000) {
            setSearchRadius(searchRadius * 2)
            _getNearestRetailers(userLocation)
          } else {
            results.forEach(item => {
              item.id = item.place_id
            })
            tmp = [...tmp, ...results]
            if (pagination.hasNextPage) {
              sleep: 2
              pagination.nextPage()
            }
            setNearestRetailers(tmp)
          }
        }
      })
    }
  }

  useEffect(() => {
    getAuthenticatedUser()
      .then(() => {
        Auth.currentUserInfo()
          .then(response => {
            setUserData(response)
          })
          .catch(err => {
            navigate('/')
          })
        getUserLocation()

        fetchCategories()
          .then(response => {
            let { data } = response
            let tmp = []
            Object.keys(data).map((key, index) => {
              tmp.push(...data[key].children)
            })
            setCategories(tmp)
            console.log('done Preloading')
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(() => {
        navigate('/')
      })
  }, [])

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    if (
      categories.filter(
        lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue
      ).length !== 0
    ) {
      setNotFound(false)
    } else {
      setNotFound(true)
    }

    return inputLength === 0
      ? []
      : categories.filter(
          lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue
        )
  }

  const getSuggestionValue = suggestion => {
    setSearchItem({ name: suggestion.name, itemId: suggestion.id })
    return suggestion.name
  }

  const renderSuggestion = suggestion => <div>{suggestion.name}</div>

  const _handleChange = event => {
    switch (event.target.name) {
      case 'product_qty':
        setProductQty(event.target.value)
        break
    }
  }

  const _onFilePicked = event => {
    setSelectedFile(event.target.files[0])
  }

  const _uploadImage = async () => {
    if (
      searchItem === '' ||
      !selectedFile ||
      !selectedRetailer ||
      product_qty === ''
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You must enter all the fields.',
      })
    } else {
      Swal.fire({
        icon: 'info',
        title: 'uploading item',
        text: 'please wait while we upload the item.',
        allowOutsideClick: false,
        showConfirmButton: false,
      })
      let { data: signedS3 } = await _getSignedS3()
      const formdata = new FormData()
      formdata.append('key', signedS3.data.fields.key)
      formdata.append('Content-type', signedS3.data.fields['Content-Type'])
      formdata.append('bucket', signedS3.data.fields.bucket)
      formdata.append(
        'X-Amz-Algorithm',
        signedS3.data.fields['X-Amz-Algorithm']
      )
      formdata.append(
        'X-Amz-Credential',
        signedS3.data.fields['X-Amz-Credential']
      )
      formdata.append('X-Amz-Date', signedS3.data.fields['X-Amz-Date'])
      formdata.append(
        'X-Amz-Security-Token',
        signedS3.data.fields['X-Amz-Security-Token']
      )
      formdata.append('Policy', signedS3.data.fields.Policy)
      formdata.append(
        'X-Amz-Signature',
        signedS3.data.fields['X-Amz-Signature']
      )
      formdata.append('file', selectedFile)
      axios
        .post(signedS3.data.url, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          Swal.close()
          navigate('/thankyou')
        })
        .catch(err => {
          Swal.showValidationMessage(`Request failed: ${err}`)
          console.group('ERROR uploading to S3')
          console.log(err)
          console.groupEnd()
        })
    }
  }

  const _getSignedS3 = () => {
    let payload = {
      name: selectedFile.name,
      type: selectedFile.type,
      categoryId: searchItem.itemId,
      productName: searchItem.name,
      productQty: product_qty,
      userId: userData.username,
      userLocation: userLocation,
      store: {
        id: selectedRetailer.id,
        name: selectedRetailer.name,
        address: selectedRetailer.vicinity,
        lat: selectedRetailer.geometry.location.lat(),
        lng: selectedRetailer.geometry.location.lng(),
      },
    }
    return storeDataUpload(payload)
  }

  const _onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const _onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const _onChange = (event, { newValue }) => {
    setSearchQuery(newValue)
  }

  const _selectRetailer = retailer => {
    setSelectedRetailer(retailer)
  }

  const inputProps = {
    placeholder: 'select item',
    value: searchQuery,
    onChange: _onChange,
  }

  const _changeLocation = () => {
    setViewMap(!viewMap)
  }

  const _newLocation = newPos => {
    let pos = {
      lat: newPos.lat(),
      lng: newPos.lng(),
    }
    _getNearestRetailers(pos)
  }

  const _showMoreLocations = () => {
    setOffSet(retailersLimit)
    setRetailersLimit(retailersLimit + 10)
  }

  const _showPreviousLocations = () => {
    setOffSet(offset - 10)
    setRetailersLimit(retailersLimit - 10)
  }

  return (
    <MAINLAYOUT>
      <BOTTOM_MENU />
      <div ref={refMap}></div>
      <section className="container p-5 mx-auto bg-gray-100">
        <div className="text-3xl font-bold text-center">found it!</div>
        <div className="mb-4 font-semibold text-center text-md">
          <span className="underline">share what you found with others!</span>
        </div>
        <div className="mt-8 mb-3 font-semibold">
          step 1: take a picture of the product
        </div>
        <section className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
          <div className="mt-5">
            <input
              type="file"
              name="file"
              onChange={_onFilePicked}
              className="w-11/12"
            />
          </div>
        </section>
        <section>
          <div className="mb-3 font-semibold">step 2: choose your store</div>
          <div className="mb-5">
            <button
              className="px-4 py-2 mx-auto font-bold text-white rounded shadow outline-none bg-teal text-light focus:outline-none focus:shadow-outline transition-colors duration-200 ease-in-out hover:bg-orange"
              type="button"
              onClick={_changeLocation}
            >
              Change Current Location
            </button>
            <div
              id="googleMap"
              className={`mt-5 ${viewMap ? 'block' : 'hidden'}`}
            >
              <GOOGLEMAP
                pinIcon={pinIcon.childImageSharp.fixed}
                draggable={true}
                setNewLocation={_newLocation}
                zoom={14}
                mapHeight="30vh"
                clickable={true}
              />
            </div>
          </div>
          {nearestRetailers && !selectedRetailer
            ? nearestRetailers.slice(offset, retailersLimit).map(retailer => (
                <article
                  onClick={() => {
                    _selectRetailer(retailer)
                  }}
                  key={retailer.id}
                  className="bg-white rounded-lg shadow-sm p-4 mb-3
              capitalize text-darkGray
              text-sm sm:text-base
              transition-colors duration-300 hover:bg-green-50
              "
                >
                  <div className="font-bold">{retailer.name}</div>
                  <div className="font-bold">{retailer.vicinity}</div>
                </article>
              ))
            : null}
          {nearestRetailers.length !== 0 ? (
            <div className="grid grid-cols-2 pb-5">
              <div>
                <button
                  className="mx-auto shadow bg-teal text-light outline-none py-2 px-4
              focus:outline-none focus:shadow-outline
              transition-colors duration-200 ease-in-out
              hover:bg-orange text-white font-bold rounded"
                  type="button"
                  disabled={offset === 0}
                  onClick={_showPreviousLocations}
                >
                  Previous locations
                </button>
              </div>
              <div>
                <button
                  className="mx-auto shadow bg-teal text-light outline-none py-2 px-4
                focus:outline-none focus:shadow-outline
                transition-colors duration-200 ease-in-out
                hover:bg-orange text-white font-bold rounded float-right"
                  type="button"
                  disabled={retailersLimit === nearestRetailers.length}
                  onClick={_showMoreLocations}
                >
                  Next locations
                </button>
              </div>
            </div>
          ) : null}
        </section>
        {nearestRetailers && nearestRetailers.length === 0 ? (
          <div className="font-semibold text-google-100 mb-3">
            getting closest locations...
          </div>
        ) : null}
        {selectedRetailer ? (
          <section className="mt-3">
            <article
              key={selectedRetailer.id}
              className="bg-white rounded-lg shadow-sm p-4 mb-3
              capitalize text-darkGray
              text-sm sm:text-base
              transition-colors duration-300 hover:bg-green-50
              "
            >
              <div className="font-bold">{selectedRetailer.name}</div>
              <div className="font-bold">{selectedRetailer.vicinity}</div>
              <div className="mt-2">
                <button
                  className="mx-auto shadow bg-teal text-light outline-none py-2 px-4
              text-white font-bold rounded
              focus:outline-none
              transition-colors duration-300 hover:bg-green-300"
                  type="button"
                  onClick={() => {
                    setSelectedRetailer(void 0)
                  }}
                >
                  change store
                </button>
              </div>
            </article>
          </section>
        ) : null}
        <section className="mb-20">
          <div className="font-semibold mb-3">step 3: enter item details</div>
          <div>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={_onSuggestionsFetchRequested}
              onSuggestionsClearRequested={_onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
            />
          </div>
          <div
            className="itemNotFound"
            style={{ display: notFound ? 'block' : 'none' }}
          >
            Product not found, please select from from our list
          </div>
          <div className="mt-5">
            <select
              className="block appearance-none w-full bg-white border-b outline-none
                  border-teal hover:border-orange px-4 py-2 pr-8 rounded shadow leading-tight 
                  focus:outline-none focus:shadow-outline"
              value={product_qty}
              onChange={_handleChange}
              name="product_qty"
            >
              <option value="">Select quantity</option>
              <option value="many">Lots</option>
              <option value="some">Some</option>
              <option value="none">None Available</option>
            </select>
          </div>
          <div className="mt-5 text-center">
            <button
              className="mx-auto shadow bg-teal text-light outline-none py-2 px-4 w-iconButton
                  focus:outline-none focus:shadow-outline
                  transition-colors duration-200 ease-in-out
                  hover:bg-orange text-white font-bold rounded"
              type="button"
              onClick={_uploadImage}
              disabled={loading}
            >
              upload
            </button>
          </div>
        </section>
      </section>
    </MAINLAYOUT>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAQhra2X6N6O4LcJpll2Y1qJzFaDuwvMbY',
})(shareit)
